<template>
  <div class="app-container">
    <div class="filter-container">
      <el-radio-group v-model="listQuery.order_type" class="filter-item" size="small" @change="handleFilter">
        <el-radio-button :label="1">外卖</el-radio-button>
        <el-radio-button :label="2">商城</el-radio-button>
      </el-radio-group>
    </div>

    <div class="filter-container">
      <el-cascader ref="tree" class="filter-item" :options="schoolWithShops" :props="props" :show-all-levels="false" placeholder="所属学校食堂店铺" collapse-tags style="width: 300px" clearable @change="handleChangeShops"></el-cascader>
      <el-date-picker class="filter-item" style="display: inline-flex;" v-model="dateArr" type="daterange" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"></el-date-picker>
      <el-button class="filter-item" type="primary" @click="handleFilter">搜索</el-button>
    </div>

    <div v-loading="listLoading">
      <el-row :gutter="20">
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ data.total_turnover || 0 }}</div>
            <div class="subtitle">总营业额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ data.total_num || 0 }}</div>
            <div class="subtitle">总订单数</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value">{{ data.customer_unit_price || 0 }}</div>
            <div class="subtitle">客单价</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value" style="color: #ff0000;">{{ data.today_turnover || 0 }}</div>
            <div class="subtitle">今日营业额</div>
          </el-card>
        </el-col>
        <el-col :sm="4">
          <el-card class="box-card">
            <div class="value" style="color: #ff0000;">{{ data.today_num || 0 }}</div>
            <div class="subtitle">今日订单数</div>
          </el-card>
        </el-col>
      </el-row>

      <!-- 折线图 -->
      <ChartOrderTurnover style="margin-top: 30px;" :data="data.order_turnover"></ChartOrderTurnover>
      <!-- 折线图 -->
    </div>
  </div>
</template>

<script>
  import ChartOrderTurnover from '@/components/ChartOrderTurnover'
  import request from "@/utils/request";
  import {mapGetters} from "vuex";
  var moment = require("moment");

  export default {
    components: {
      ChartOrderTurnover,
    },
    data() {
      return {
        listLoading:false,
        dateArr: null,
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        listQuery: {
          shop_ids: [],
          start_time: "",
          end_time: "",
          order_type: 1,
        },
        data: [],
        // 店铺筛选
        schoolWithShops: [],
        props: { multiple: true },
      };
    },
    created() {
      this.getSchoolWithShop();
      // 默认近七天数据
      this.listQuery.start_time = moment().subtract(7, 'days').format("YYYY-MM-DD");
      this.listQuery.end_time = moment().format("YYYY-MM-DD");
      this.dateArr = [this.listQuery.start_time, this.listQuery.end_time];
      this.getList();
    },
    watch: {
      "listQuery.order_type": {
        handler(newValue, oldValue) {
          this.listQuery.shop_ids = [];
          // 清空选中的节点
          this.$refs.tree.$refs.panel.clearCheckedNodes();
          // 设置为空可以让节点不高亮显示
          this.$refs.tree.$refs.panel.activePath = [];
          this.getSchoolWithShop();
        },
        deep: true
      },
      dateArr(newVal, oldVal) {
        if (newVal) {
          this.listQuery.start_time = newVal[0] + ' 00:00:00';
          this.listQuery.end_time = newVal[1] + ' 23:59:59';
        } else {
          this.listQuery.start_time = "";
          this.listQuery.end_time = "";
        }
      },
    },
    methods: {
      getList() {
        this.listLoading = true
        return request({
          url: "/api/backend/finance/dataAnalysis",
          method: "post",
          data: this.listQuery,
          timeout: 10 * 60 * 1000,
        }).then(res => {
          this.data = res.data;
          this.listLoading = false
        });
      },
      handleFilter() {
        if(!this.listQuery.start_time || !this.listQuery.end_time){
          this.$message.warning('请选择开始和结束日期')
          return
        }
        this.getList();
      },
      getSchoolWithShop() {
        request({
          url: "/api/backend/school/schoolWithShop",
          method: "get",
          params: {
            type: this.listQuery.order_type
          }
        }).then(response => {
          let data = response.data;
          let schoolWithShops = [];

          // 区分外卖与商城
          if (this.listQuery.order_type === 1) {
            data.forEach(element => {
              let obj = {
                value: element.id,
                label: element.school_name,
                children: []
              };
              element.canteens.forEach(element2 => {
                let obj2 = {
                  value: element2.id,
                  label: element2.canteen_name,
                  children: []
                };
                element2.shops.forEach(element3 => {
                  let obj3 = {
                    value: element3.id,
                    label: element3.shop_name,
                  };
                  obj2.children.push(obj3);
                });
                obj.children.push(obj2);
              });
              schoolWithShops.push(obj);
            });
          } else if(this.listQuery.order_type === 2) {
            data.forEach(element => {
              let obj = {
                value: element.id,
                label: element.school_name,
                children: []
              };
              element.shop_list.forEach(element2 => {
                let obj2 = {
                  value: element2.id,
                  label: element2.shop_name,
                };
                obj.children.push(obj2);
              });
              schoolWithShops.push(obj);
            });
          }

          this.schoolWithShops = schoolWithShops;
        });
      },
      // 更新多选店铺
      handleChangeShops(data) {
        let shop_ids = [];
        if (data.length > 0) {
          // 区分外卖与商城
          if (this.listQuery.order_type === 1) {
            data.forEach(element => {
              shop_ids.push(element[2]);
            });
          } else if(this.listQuery.order_type === 2) {
            data.forEach(element => {
              shop_ids.push(element[1]);
            });
          }
        }
        this.listQuery.shop_ids = shop_ids;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .box-card {
    text-align: center;
    .value {
      color: #546DFE;
      font-weight: bold;
      font-size: 26px;
    }
    .subtitle {
      font-size: 12px;
      color: gray;
      margin-top: 6px;
    }
  }
</style>
